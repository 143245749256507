import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import { isEmpty, omitBy } from "lodash";
import isMobile from "@src/utils/isMobile";

const Story = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { classID, schoolID, postID, target, userID, email, hash, schoolLinkSchoolId, appDownload } = parsedLocation;
  const isMobileDevice = isMobile();

  const commonParams = new URLSearchParams(omitBy({ hash: hash?.toString() }, isEmpty));
  useRedirectHandler(() => {
    if (appDownload) {
      if (isMobileDevice.android.device) {
        return "https://play.google.com/store/apps/details?id=com.classdojo.android";
      }
      if (isMobileDevice.apple.device) {
        return "https://apps.apple.com/app/classdojo/id552602056";
      }
    }

    const getBaseUrl = () => {
      if (classID && postID) {
        return `https://home.classdojo.com/#/story/${postID}/class/${classID}?`;
      }
      if (schoolID && postID) {
        return `https://home.classdojo.com/#/story/${postID}/school/${schoolID}?`;
      }
      if (target === "verifyEmail" && userID && email && hash) {
        const redirectPath = `https://lite.classdojo.com/emailVerification/?userId=${userID}&email=${encodeURIComponent(
          email as string,
        )}&hash=${hash}&target=home&`;
        return schoolLinkSchoolId ? `${redirectPath}schoolLinkSchoolId=${schoolLinkSchoolId}&` : redirectPath;
      }
      return "";
    };

    // hash should be passed for all the cases above

    const url = getBaseUrl();
    if (!url) {
      // If the user comes from a school invite link, redirect to the school invite page
      return schoolLinkSchoolId
        ? `https://home.classdojo.com/#/schoolInvite?s=${schoolLinkSchoolId}`
        : `https://home.classdojo.com/#/story?${commonParams}`;
    }

    return `${url}${commonParams}`;
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Story;
